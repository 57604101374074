<template>
    <div v-if="developer">
        <h1 class="title">{{ developer.name }}</h1>
        <h2>Including:</h2>
        <ul v-if="developer.aliases.length > 1">
            <li v-for="alias in developer.aliases"
                :key="alias.id">
                <label class="checkbox">
                    <input v-model="alias.selected"
                        type="checkbox">
                    {{ alias.name }}
                </label>
            </li>
        </ul>
        <h2 class="subtitle is-4 mt-5">
            {{ games.length }} Game{{ games.length == 1 ? '' : 's' }}
        </h2>
        <div>
            <game-row v-for="game in filteredGames"
                :key="game.id"
                :game="game"
                :show-rank="false"
                :show-rank-in-details="true"></game-row>
        </div>
    </div>
</template>

<script>
import Developer from '../models/Developer';
import Game from '../models/Game';
import GameRow from './GameRow';

export default {
    components: { GameRow },
    data() {
        return {
            developer: null,
            games: [],
        }
    },
    async created() {
        let data = await fetch(`${process.env.VUE_APP_API_URL}developers/${this.$route.params.slug}/`)
            .then(resp => resp.json());
        this.developer = new Developer(data);
        this.developer.aliases.forEach(x => x.selected = true);

        data = await fetch(`${process.env.VUE_APP_API_URL}games/?developer=${this.developer.id}&order_by=year_of_release`)
            .then(resp => resp.json());
        this.games = data.results.map(x => new Game(x));

        this.emitter.emit('title', this.developer.name);
    },
    computed: {
        selectedAliases() {
            return this.developer.aliases.filter(x => x.selected);
        },
        filteredGames() {
            const selectedAliasIds = this.selectedAliases.map(x => x.id);
            return this.games.filter(x => {
                const developerIds = x.developers.map(y => y.id);
                const intersection = selectedAliasIds.filter(y => developerIds.includes(y));
                return intersection.length > 0;
            })
        }
    }
}
</script>
